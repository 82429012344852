.IonModal {
  --height: auto;
  --backdrop-opacity: 0.9;
  --border-style: solid;
  --border-width: 1px;
  --border-radius: 10px;

  &.IonModal__fullHeight {
    --height: 100%;
    --width: 100%;
  }
}

.Text__Header {
  font-weight: 500;
  font-size: 20px;
  text-align: center;
}

.Text__Content {
  text-align: center;
  white-space: pre-wrap;
}

.Close__button {
  color: var(--color-main-high);
  width: 48px;
  height: 48px;
  margin-left: -10px;
}
