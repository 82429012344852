.AboutCard {
  border-radius: 15px;
  width: 100%;
  box-shadow: 0 0 3px rgba(255, 255, 255, 0.1);
  background: linear-gradient(
    to bottom,
    var(--color-search-block),
    var(--color-card-auth)
  ) !important;

  &:hover {
    background: var(--color-search-block) !important;
    cursor: pointer;
  }
}
