.HeaderColored {
  color: #696ff1;
  font-family: 'Montserrat';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
  margin-top: 15px;
  line-height: 150%;
}

.RoundNumber {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  background: var(--color-search-block);
  border-radius: 50%;

  p {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 25px;
  }
}

.RoundParagraph {
  line-height: 150%;
}
