.CookiesBanner {
  background: linear-gradient(to bottom, #fff, #ddd);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 0;
  right: 0;

  width: 450px;
  max-width: calc(100% - 80px);
  margin: 20px 40px;

  padding: 10px 15px;
  height: fit-content;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 9999;

  &.CookiesBanner__mobile {
    width: 100%;
    max-width: calc(100% - 20px);
    margin: 25px 10px;
  }
}
