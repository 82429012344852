.TextLink {
  width: fit-content;
  color: #929292 !important;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 2.5px;
  text-decoration-style: dotted;

  &:hover {
    color: var(--color-main-high) !important;
    cursor: pointer;
  }

  &.TextLink__black {
    color: black !important;
    text-decoration-thickness: 2px;
    text-decoration-style: dotted;
    &:hover {
      color: grey !important;
      cursor: pointer;
    }
  }

  &.TextLink__white {
    color: var(--color-main-high) !important;
    text-decoration-thickness: 2px;
    // text-underline-offset: 4px;
    text-decoration-style: dotted;
    &:hover {
      color: grey !important;
      cursor: pointer;
    }
  }
}
