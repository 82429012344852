$border-default: 1px solid rgba(255, 255, 255, 0.05);
$border-desktop: 1px solid rgba(255, 255, 255, 0.5);

:root {
  --ion-grid-column-padding: 3px;

  /* Main theme: related with manifest.json > theme_color and capacitor.config.ts color */
  --ion-background-color: #161616;

  /* Elements */
  --color-card: #211f1f;
  --color-card-auth: #272424;
  --color-navigation-elements: rgb(200, 200, 200);
  --color-search-block: #333131;

  /* Text */
  --color-main: rgb(255, 255, 255);
  --color-main-high: rgba(255, 255, 255, 0.8);
  --color-main-medium: rgba(255, 255, 255, 0.6);
  --color-main-low: rgba(255, 255, 255, 0.4);
  --color-main-hover: rgba(255, 255, 255, 0.05);

  /* ION - Primary */
  --ion-color-primary: #ffffff;
  --ion-color-primary-rgb: 255, 255, 255;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #e0e0e0;
  --ion-color-primary-tint: #ffffff;

  --ion-color-secondary: #696ff1;
  --ion-color-secondary-rgb: 105, 111, 241;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #5c62d4;
  --ion-color-secondary-tint: #787df2;

  /* ION - Success */
  --ion-color-success: #6dac86;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /* Z-Index */
  --z-index-A: 700;
  --z-index-B: 600;
  --z-index-C: 500;
  --z-index-0: 0;
  --z-index-X: -500;
  --z-index-Y: -600;
  --z-index-Z: -700;
}

/* Ionic components */
ion-content {
  --background: none;
  overflow: auto;

  &.photo_background {
    background: url(../assets/background.jpg) no-repeat 0 0;
    background-size: cover;
    background-position: center;
  }
}

/* Hide scrollbar */
ion-content:not(.withScrollbar)::part(scroll) {
  scrollbar-width: none;
}

ion-select,
ion-textarea,
ion-input {
  min-height: 40px !important;
  --highlight-color-focused: none !important;
}

ion-select::part(icon) {
  position: absolute;
  right: 10px;
}

ion-select::part(text) {
  margin-right: 30px;
}

ion-button {
  text-transform: none;
  --border-radius: 15px;
  --padding-top: 0;
  --padding-bottom: 0;
}

ion-checkbox {
  --size: 24px;
  max-width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 0px;
  --checkmark-color: white;
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: 1px solid var(--color-main-high);
  background: transparent;
}

ion-popover::part(content) {
  border: none;
  margin-left: 10px;
}

/* No padding top at toolbars (for IOS) */
ion-modal ion-header ion-toolbar:first-of-type {
  padding-top: 0;
}

/* <Header> */
ion-toolbar {
  --background: none;
  --padding-start: 10px;
  --min-height: 50px;

  border-bottom: solid 1px rgba(255, 255, 255, 0.05);

  /* Extra padding top (for IOS) */
  &.forceAddPaddingTop {
    padding-top: 50px !important;
  }
}

/* <Footer> */
ion-tab-bar {
  height: 70px;
  border-top: $border-default;
  --background: transparent;

  // This is related with implementation
  // Of custom tab bar at AppTabLogo.styles.scss
  &.fillColorToBottom {
    height: 100px;
    --ion-safe-area-bottom: 0px;
  }
}

ion-menu {
  --side-min-width: 200px;
  --side-max-width: 200px;
  z-index: var(--z-index-A);
}

ion-card {
  margin: 0;
  padding: 10px;
}

// Rewrite only "default" color scheme
ion-card:not(.ion-color) {
  background: var(--color-search-block);
}
