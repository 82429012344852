// $font-family-main
@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

// $font-family-secondary
@font-face {
  font-family: 'GillSans';
  src: url('../assets/fonts/GillSans.woff') format('woff');
  font-weight: 200;
  font-style: regular;
}
