.radioButton {
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  padding: 12px;
  cursor: pointer;
  white-space: pre-wrap;
  text-align: center;

  &.radioButton__normal {
    border: 1px solid rgba(255, 255, 255, 0.4);
    color: rgba(255, 255, 255, 0.6);
  }

  &.radioButton__normal_active {
    border: 2px solid #787df2;
    color: #787df2;
  }

  &.radioButton__custom {
    border: 1px solid #cecece;
    color: #686868;
  }

  &.radioButton__custom_active {
    border: 2px solid #696ff1;
    color: #696ff1;
  }
}
