@import '../../Styles/tokens';

.ActionButton {
  display: flex;
  padding: 16px 32px 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 500;
  font-family: 'Open Sans';
  border-radius: 100px;
  border: none;
  color: $cwhite;
  background: $cblue;
  cursor: pointer;
  @media screen and (max-width: $mobile-max-width) {
    font-size: 18px;
  }
  @media screen and (max-width: 320px) {
    font-size: 16px;
  }
  img {
    position: relative;
    top: 1px;
    @media screen and (max-width: $mobile-max-width) {
      height: 16px;
      width: 16px;
    }
  }
  &:hover {
    background: #5057ce;
  }

  &.ActionButton__animation {
    transition: 0.8s;
    opacity: 0;
    transform: translateY(100px);
  }

  &.ActionButton__mini {
    padding: 8px;
    gap: 4px;
  }
}

.InstagramButton__link {
  font-family: 'Montserrat';
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  color: #f5f5f5;
  position: relative;
  z-index: 0;
  margin-top: 15px;
  @media screen and (max-width: $mobile-max-width) {
    font-size: 30px;
  }
  @media screen and (max-width: 320px) {
    font-size: 36px;
  }
}

.InstagramButton__background {
  background: linear-gradient(45deg, #833ab4, #fd1d1d, #fcb045);
  &:hover {
    background: linear-gradient(45deg, #fd1d1d, #fcb045);
  }
}
